import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form.js";
import {Button, Card, Col, Form, Input, Row, Select, Space, Spin} from "antd";
import _ from "lodash";
import {Showable} from "./Switcher.js";
import {callWithCatch, pp} from "./utils.js";
import MyWS from "./MyWS.js";
import {Bot} from "lucide-react";
import HtmlDiv from "./HtmlDiv.js";
import {useEffectOnce} from "react-use";

let byTypes = {
    "כתב תביעה": ["התובע", "התובעת", "התובעות", "התובעים",],
    "כתב הגנה": ["הנתבע", "הנתבעת", "הנתבעות", "הנתבעים",],
    "בקשה": ["המבקש", "המבקשת", "המבקשות", "המבקשים",],
    "כתב תשובה": ["המשיב", "המשיבה", "המשיבות", "המשיבים",],
    "כתב עתירה": ["העותר", "העותרת", "העותרות", "העותרים",],
    "כתב ערעור": ["המערער", "המערערת", "המערערות", "המערערים",],
    "כתב ערער": ["העורר", "העוררת", "העוררות", "העוררים",],
}

let getByTypes = (type) => {
    return byTypes[type] || ["המשיב", "המשיבה", "המשיבות", "המשיבים",]
}

const AiStream = observer(({params, loading, setLoading}) => {
    const [result, setResult] = useState(false);

    let gen = async () => {
        if (!params)
            return;

        setLoading(true);
        setResult(false);
        callWithCatch({
            url: `/searches/ai`,
            method: 'post',
            params: {brief_gen: true, ...params},
            onErr: () => {
                setLoading(false)
            },
            onSuccess: data => {
                let sub;
                if (data.text) {
                    setLoading(false);
                    setResult(data)
                }

                if (data.sub_to)
                    sub = MyWS(data.sub_to, r => {
                        setResult(r)
                        if (r.done) {
                            setLoading(false);
                            sub.unsubscribe();
                        }
                    })
            }
        })
    }

    useEffect(() => {
        gen()
    }, [params])

    if (!params)
        return null;

    let title = <Space><Bot/><Spin/></Space>;
    if (result?.done)
        title = <Space><Bot/>
            <div>סיימתי!</div>
        </Space>;

    if (result?.err)
        pp(result?.err)

    let spinText = s => {
        if (s.includes("@@"))
            return <span><span><HtmlDiv html={s.replace(/@@/, "")}/></span><span><Spin/></span></span>

        return <HtmlDiv html={s}/>
    }

    return <div className="w-full sm:flex-grow m-4">
        <Card size="small" title={title}>
            {result &&
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "500px"}} className="">
                    {spinText(result.text)}
                    <br/>
                    {result.done &&
                        <div style={{fontSize: "10px", color: "#9CA3AF"}}>סיכום הAI בבטא ויכול לעשות טעויות. אנא ודאו את
                            תשובותיו עם הפסיקה.</div>}
                </div>}
        </Card>
        <br/>
        <br/>
    </div>

})


export default observer(() => {
    const [bg, setBg] = useState(null);
    const [bgs, setBgs] = useState(null);
    const [gen, setGen] = useState(false);

    useEffectOnce(() => {
        callWithCatch({
            url: "/brief_gens",
            method: 'get',
            onSuccess: data => {
                setBgs(data.brief_gens)
            },
        })
    })

    if (gen)
        return <Gen cancel={() => setGen(false)} />

    return <div className="flex flex-wrap">
        <div className="w-1/4">
            <div className="space-y-4">
                <Button type="primary" onClick={() => setGen(true)}>כתב טענות חדש</Button>
                {bgs && bgs.map(bg => <div key={bg.id}><a onClick={() => setBg(bg)}>{bg.name}</a></div>)}
            </div>
        </div>
        <div className="w-3/4">
            { bg && <Card title={bg.name}>
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "600px"}} className="">
                    <HtmlDiv html={bg.result} />
                </div>
            </Card>}
        </div>
    </div>

return
});

let Gen = observer(({cancel}) => {
    let [form] = useForm();
    const type = Form.useWatch('type', form);
    const [gen, setGen] = useState(false);
    const [loading, setLoading] = useState(false);

    let types = _.keys(byTypes);
    let supportedTypes = ["כתב תביעה", "בקשה"];

    return <Row gutter={32}>
        <Col>
            <Form form={form}
                  onFinish={(params) => setGen(params)}
                  layout="vertical"
            >
                <Form.Item name="type" label="סוג כתב טענות">
                    <Select options={types.map(t => {
                        if (supportedTypes.includes(t))
                            return {label: t, value: t};
                        else {
                            let label = <div className="flex space-between">
                                <div className="flex-1">{t}</div>
                                <div className="flex-1 text-left">בקרוב!</div>
                            </div>
                            return {label, value: t, disabled: true}
                        }
                    })}
                            onChange={() => {
                                form.setFieldsValue({by: null});
                            }}
                            style={{width: "175px"}}
                    />
                </Form.Item>
                <Showable v={type}>
                    {type && <Form.Item name="pov" label="מוגש ע״י">
                        <Select options={getByTypes(type).map(t => ({label: t, value: t}))}
                                style={{width: "175px"}}
                        />
                    </Form.Item>}
                    <Form.Item name="subject" label="נושא">
                        <Input style={{width: "450px"}}/>
                    </Form.Item>
                    <Form.Item name="background" label="רקע">
                        <Input.TextArea rows={10} style={{width: "450px"}}/>
                    </Form.Item>
                </Showable>
                <Space>
                    <Button type="primary" loading={loading} htmlType="submit">צור כתב טענות</Button>
                    <Button onClick={cancel}>ביטול</Button>
                </Space>

            </Form>
        </Col>
        <Col>
            <AiStream params={gen} loading={loading} setLoading={setLoading}/>
        </Col>
    </Row>
})

